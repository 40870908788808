import React, { useEffect } from 'react'
import PageHeader from '../components/home/PageHeader'
import Nav from '../components/common/Nav'
import Layout from '../components/common/Layout'
import Fade from 'react-reveal/Fade'
import SelectedCases from '../components/home/SelectedCases'

const HomePage = ({ data }) => {
  return (
    <Layout>
      <Nav />

      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <PageHeader />
        </Fade>
        <SelectedCases />
      </main>
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query {
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/project/" } }
      limit: 3
    ) {
      nodes {
        id
        frontmatter {
          title
          featuredImage
        }
        fields {
          slug
        }
      }
    }
  }
`
