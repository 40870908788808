import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@mantine/core'
import styles from './PageHeader.module.scss'
import Button from '../common/Button'

import loadable from '@loadable/component'

const AnimationComponent = loadable(() =>
  import('../animations/AnimationComponent.js')
)

const PageHeader = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.heading}>
            <h1 className={styles.title}>
              Designer and builder who gets things done.
              <br /> Currently Co-founder at{' '}
              <span style={{ fontWeight: 600 }}>Atlas</span> where I turn
              complex <br />
              technologies into human-friendly solutions.
            </h1>
            <h1 className={styles.mobileTitle}>
              Designer and builder who gets things done. Currently Co-founder at{' '}
              <span style={{ fontWeight: 600 }}>Atlas</span> where I turn
              complex technologies into human-friendly solutions.
            </h1>
          </div>
          <div className={styles.grid}>
            <div>
              <p>
                I work fast, like{' '}
                <span style={{ fontWeight: 600 }}>really fast</span>. Quick
                iteration allows us to zoom through explorations until we arrive
                at something that feels just right.
              </p>
            </div>
            <div>
              <p>
                At the intersection of brand awareness, technical understanding,
                and visual reductiveness,{' '}
                <span style={{ fontWeight: 600 }}>great software</span> is
                built.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default PageHeader
