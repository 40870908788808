import React, { useState, useEffect, useRef } from 'react'
import styles from './SelectedCases.module.scss'
import 'intersection-observer'
import { Link } from 'gatsby'

const SelectedCases = () => {
  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <h3>Selected cases</h3>
        <div className={styles.fullWidthRow}>
          <Link to="/project/atlas">
            <div className={`${styles.case}`}>
              <img src="/images/atlas/atlas-hero.svg" />
              <div className={styles.caseDetails}>
                <h2>Atlas</h2>
                <p>Design & Development</p>
              </div>
            </div>
          </Link>
        </div>
        <div className={styles.twoColumnsRow}>
          <Link to="/project/econnect">
            <div className={styles.case}>
              <img src="/images/econnect/hero.png" />
              <div className={styles.caseDetails}>
                <h2 style={{ color: '#fff' }}>Econnect</h2>
                <p style={{ color: '#fff' }}>Design</p>
              </div>
            </div>
          </Link>
          <Link to="/project/kenya-premier-league">
            <div className={styles.case}>
              <img src="/images/kpl/kpl-featured.png" />
              <div className={styles.caseDetails}>
                <h2 style={{ color: '#fff' }}>Kenya Premier League</h2>
                <p style={{ color: '#fff' }}>Design & Development</p>
              </div>
            </div>
          </Link>
        </div>
        <div className={styles.fullWidthRow}>
          <Link to="/project/ntention">
            <div className={styles.case}>
              <img src="/images/ntention/Space.jpg" />
              <div className={styles.caseDetails}>
                <h2 style={{ color: '#fff' }}>Ntention</h2>
                <p style={{ color: '#fff' }}>Design & Development</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default SelectedCases
